/**=====================
     62. Responsive CSS Start
==========================**/
/* ========= min and max scss for 1200 to 1366 screen ========= */
@media (max-width: 1800px) {
  .todo-wrap .todo-list-body .task-container div.d-flex {
    min-width: 240px;
    justify-content: flex-end;
  }
  .caller-img {
    display: none;
  }
}
@media screen and (max-width: 1660px) {
  .photos ul li {
    width: 26%;
  }
  .bar-chart canvas {
    width: 100% !important;
  }
  .chat-box .chat-history .call-icons ul li {
    border: 1px solid #9B9B9B;
  }
  .chat-box .chat-history .total-time h2 {
    color: #9B9B9B;
  }
  .chat-box .chat-history .call-content .btn {
    width: auto;
    margin: 0 auto;
  }
  .chat-box .chat-history .call-content > div {
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    width: 100%;
    padding: 30px;
  }
  .chat-box .chat-history .receiver-img {
    margin-top: 25px;
  }
  .chat-left-aside .people-list {
    height: 520px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: 440px;
  }
  .chat-box .people-list ul {
    max-height: 450px;
    overflow: auto;
  }
  .call-chat-body .chat-box {
    overflow: hidden;
    height: 625px;
  }
  .call-chat-body .chat-box .people-list ul {
    max-height: 550px;
    overflow: auto;
  }
  .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
  }
  .caller-img img {
    opacity: 0.7;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -78px;
  }
}
@media screen and (max-width: 1600px) {
  .page-wrapper.horizontal-wrapper.material-type .page-header {
    max-width: 1320px;
  }
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .sidebar-wrapper,
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .page-body {
    max-width: 1320px;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
    margin-right: 10px;
  }
}
@media (max-width: 1470px) {
  .basic_table .table-responsive .table tbody tr td:nth-child(n+2) {
    min-width: 180px;
  }
  .signal-table.table-responsive .table tbody tr td:nth-child(n+2) {
    min-width: 240px;
  }
}
@media screen and (max-width: 1660px) and (min-width: 1200px) {
  .xl-none {
    display: none;
  }
  .xl-60 {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-23 {
    max-width: 23%;
    flex: 0 0 23%;
  }
  .xl-25 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .xl-30 {
    max-width: 30%;
    flex: 0 0 30%;
  }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}
@media (max-width: 1280px) {
  .img-gallery-set1,
  .img-gallery-set5 {
    display: none;
  }
  .box-layout.page-wrapper.horizontal-wrapper .page-header {
    padding-left: unset;
    max-width: unset;
    left: unset;
  }
  .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper {
    width: unset;
  }
}
@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .docs-buttons,
  .docs-toggles {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1200px) {
  .flot-chart-container-small {
    height: 227px;
  }
  .call-chat-sidebar {
    max-width: 262px;
  }
  .product-box .product-details {
    padding: 15px;
  }
  .product-box .product-details p {
    margin-bottom: 10px;
  }
  .file-manager .folder .folder-box p span {
    float: unset;
    display: block;
  }
  .blog-box .blog-details-main .blog-bottom-details {
    margin: 20px 10px;
  }
  .blog-box .blog-details-main .blog-social li {
    padding: 0px 5px;
  }
  .blog-box.blog-shadow .blog-details {
    padding: 10px;
  }
  .browser-widget .media-img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  .browser-widget img {
    height: 50px;
  }
  .blog-page .blog-box.blog-shadow {
    height: 455px;
  }
  .blog-page .blog-box.blog-shadow img {
    height: 455px;
  }
  .blog-box .blog-details .blog-social {
    margin-top: 12px;
  }
  .blog-box .blog-details .blog-social li:first-child {
    padding-right: 8px;
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 8px;
  }
  .blog-box.blog-list .blog-details {
    margin: 10px;
  }
  .flot-chart-container {
    height: 130px;
  }
  .custom-card .card-profile img {
    height: 115px;
  }
  .custom-card .profile-details h4 {
    font-size: 18px;
  }
  .custom-card .profile-details h6 {
    margin-bottom: 24px;
  }
  .custom-card .card-footer > div h3 {
    font-size: 18px;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex {
    display: block !important;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email {
    width: 100%;
    text-align: center;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email .mr-3 {
    margin-right: 0 !important;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .flex-grow-1 {
    text-align: center;
  }
  .email-wrap .email-right-aside .email-body .attachment ul li img {
    width: 73px;
    height: 73px;
  }
  .email-wrap .email-right-aside .email-body .email-top {
    line-height: 50px;
  }
  .email-wrap .email-right-aside .email-body .email-top .float-right {
    display: none !important;
  }
  .email-wrap .email-right-aside .email-body .email-top h5 {
    padding: 13px 0;
  }
  .email-wrap .email-right-aside .email-body .inbox {
    max-height: 668px;
    overflow: auto;
  }
  .email-wrap .email-right-aside .email-body .inbox .d-flex {
    padding: 15px;
  }
  .email-wrap .email-right-aside .email-body .inbox .d-flex .media-size-email .me-3 {
    margin-right: 10px !important;
  }
  .email-wrap .email-right-aside .email-body .inbox .flex-grow-1 h4 {
    font-size: 16px;
  }
  .email-wrap .email-right-aside .email-body .email-compose .cke_contents.cke_reset {
    max-height: 144px;
    width: 99%;
  }
  .email-wrap .email-right-aside .email-body .email-compose .compose-border {
    margin: 0;
  }
  .crm-activity {
    height: 291px;
    overflow-y: auto;
  }
  .crm-activity .d-flex .flex-grow-1 .dates {
    display: flex;
  }
  .chat-body {
    padding: 10px;
  }
  .chat-box .chat-history .call-content .btn {
    font-size: 15px;
  }
  .chat-box .chat-history .total-time h2 {
    font-size: 30px;
  }
  .chat-box .chat-history .receiver-img {
    margin-top: 30px;
  }
  .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .chat-box .chat-history .call-icons ul li {
    width: 50px;
    height: 50px;
    padding: 7px;
  }
  .chat-box .user-image {
    width: 30px;
    height: 30px;
    margin-top: 3px;
    margin-right: 3px;
  }
  .chat-box .about {
    margin-top: 0;
  }
  .chat-box .border-tab.nav-tabs .nav-item a {
    padding: 13px 4px !important;
    color: #59667a !important;
    letter-spacing: 0;
    font-size: 12px !important;
    line-height: 6;
  }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 376px;
    overflow-y: auto;
  }
  .chat-box .chat-menu .user-profile .image .avatar img {
    width: 100px;
    height: 100px;
  }
  .chat-box .chat-menu .user-profile .image .icon-wrapper {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
  .chat-left-aside .people-list {
    height: 420px;
  }
  .chat-left-aside .people-list ul {
    max-height: 376px;
    overflow-y: auto;
  }
  .chat-left-aside .status-circle {
    top: 4px;
    left: 36px;
  }
  .blog-box .blog-details-main .blog-bottom-details {
    margin: 20px 10px;
  }
  .blog-box .blog-details-main .blog-social li {
    padding: 0px 10px;
  }
  .blog-box.blog-shadow .blog-details {
    padding: 10px;
  }
  .browser-widget .media-img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  .browser-widget img {
    height: 50px;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex {
    display: block;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email {
    width: 100%;
    text-align: center;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .media-size-email .mr-3 {
    margin-right: 0 !important;
  }
  .email-wrap .email-left-aside .email-app-sidebar .d-flex .flex-grow-1 {
    text-align: center;
  }
  .email-wrap .email-right-aside .email-body .attachment ul li img {
    width: 73px;
    height: 73px;
  }
  .email-wrap .email-right-aside .email-body .email-top {
    line-height: 50px;
  }
  .email-wrap .email-right-aside .email-body .email-top .float-right {
    display: none !important;
  }
  .email-wrap .email-right-aside .email-body .email-top h5 {
    padding: 13px 0;
  }
  .email-wrap .email-right-aside .email-body .inbox {
    max-height: 668px;
    overflow: auto;
  }
  .email-wrap .email-right-aside .email-body .inbox .d-flex {
    display: block;
    text-align: center;
  }
  .email-wrap .email-right-aside .email-body .inbox .d-flex .media-size-email .mr-3 {
    margin-right: 0 !important;
  }
  .email-wrap .email-right-aside .email-body .inbox .flex-grow-1 {
    text-align: start;
  }
  .email-wrap .email-right-aside .email-body .inbox .flex-grow-1 h6 {
    font-size: 14px;
  }
  .email-wrap .email-right-aside .email-body .email-compose .cke_contents.cke_reset {
    max-height: 144px;
    width: 99%;
  }
  .email-wrap .email-right-aside .email-body .email-compose .compose-border {
    margin: 0;
  }
  .alert-dismissible p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 181px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 96% !important;
  }
}
@media only screen and (max-width: 1366px) {
  .bookmark-wrap .task-sidebar {
    height: auto;
  }
  .btn-radio .btn-group .btn {
    padding: 4px 8px;
  }
  .grid-options ul li:last-child {
    display: none;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 25%;
  }
  .cart .qty-box {
    width: 40%;
  }
  .alert-dismissible strong ~ p {
    display: inline;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    width: 95%;
  }
  .map-js-height {
    height: 350px;
  }
  .chat-right-aside {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
  }
  .chat-right-aside .chat .chat-message .smiley-box {
    width: 45px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: 364px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
    margin-top: -30px;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box .message {
    width: 100%;
    padding: 15px;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    font-size: 20px;
  }
  .chat-box .chat-history .receiver-img img {
    height: 150px;
  }
  .call-chat-body .chat-box {
    overflow: hidden;
    height: 565px;
  }
  .equal-height-xl {
    height: 517px;
  }
  .authentication-main .auth-innerright {
    background-size: cover;
  }
  .grp-btns {
    display: inline-block;
  }
  .button-builder .button-generator-bx {
    position: relative;
    width: 100%;
  }
  .page-builder .btn-group {
    display: inline-block;
    margin-bottom: 10px;
  }
  .page-builder .btn-grid {
    margin-bottom: 15px;
    margin-right: 0px;
    border-right: none;
    display: inline-block;
  }
  .page-builder .ge-canvas.ge-layout-desktop {
    margin-top: 50px;
  }
  .ecommerce-widget .total-num {
    font-size: 23px;
    letter-spacing: 0px;
  }
  .support-ticket-font .total-num {
    font-size: 22px;
  }
  .order-history table.dataTable.table thead th {
    padding-right: 50px;
  }
  .custom-card {
    padding: 15px;
  }
  .custom-card .card-social li a {
    padding: 12px;
  }
  .map-chart .chart-container {
    height: 300px;
  }
  .speed-chart .chart-container {
    height: 250px;
    margin-top: -40px;
    margin-bottom: 100px;
  }
  .candidcahrt {
    height: 360px;
  }
  .status-details h3 {
    font-size: 18px;
  }
  .chart-widget-top .total-value {
    font-size: 20px;
  }
  .status-widget .card-body {
    padding: 10px;
  }
  .browser-widget .d-flex {
    flex-wrap: wrap;
  }
  .browser-widget .d-flex .flex-grow-1 h3 {
    font-size: 20px;
  }
  .browser-widget .d-flex .flex-grow-1 p {
    margin-bottom: 2px;
  }
  .browser-widget .d-flex .flex-grow-1 h4 {
    font-size: 20px;
  }
  .testimonial i {
    font-size: 46px;
  }
  .testimonial p {
    margin-top: 20px;
  }
  .redial-social-widget {
    width: 125px;
    height: 125px;
  }
  .redial-social-widget i {
    width: 100px;
    height: 100px;
    font-size: 35px;
  }
  .chat-body {
    padding: 10px;
  }
  .chat-box .chat-history .call-content .btn {
    font-size: 15px;
  }
  .chat-box .chat-history .total-time h2 {
    font-size: 30px;
  }
  .chat-box .chat-history .receiver-img {
    margin-top: 30px;
  }
  .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .chat-box .chat-history .call-icons ul li {
    width: 50px;
    height: 50px;
    padding: 7px;
  }
  .chat-box .user-image {
    width: 45px;
    height: 45px;
  }
  .chat-box .about {
    margin-top: 0;
  }
  .chat-box .border-tab.nav-tabs .nav-item a {
    padding: 13px 4px !important;
    color: #59667a !important;
    letter-spacing: 0;
    font-size: 12px !important;
    line-height: 6;
  }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 455px;
    overflow-y: auto;
  }
  .chat-box .chat-menu .user-profile {
    margin-top: 0;
  }
  .chat-box .chat-menu .user-profile .image .avatar img {
    width: 100px;
    height: 100px;
  }
  .chat-box .chat-menu .user-profile .image .icon-wrapper {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
  .chat-box .chat-menu .user-profile .user-content p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .chat-box .chat-menu .user-profile .user-content h5 {
    margin: 20px 0;
  }
  .chat-box .chat-menu .user-profile .user-content hr {
    margin: 20px 0;
  }
  .chat-box .chat-menu .user-profile .follow .follow-num {
    font-size: 20px;
  }
  .chat-left-aside .people-list {
    height: 490px;
  }
  .chat-left-aside .people-list ul {
    max-height: 434px;
    overflow-y: auto;
  }
  .status-circle {
    top: 4px;
    left: 37px;
  }
  .mega-menu .onhover-show-div {
    left: 164px;
  }
  .img-cropper .docs-toggles {
    margin-top: 8px;
  }
  .bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
    left: -21px;
  }
  #advance-5_wrapper .dataTables_paginate {
    margin-bottom: 8px;
  }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 67px !important;
  }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 67px !important;
  }
  .reset-password-box .theme-form .form-group .btn {
    padding: 6px 22px;
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1200px) {
  .page-wrapper.horizontal-wrapper.material-type .page-header {
    max-width: 1140px;
  }
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .sidebar-wrapper,
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .page-body {
    max-width: 1140px;
  }
}
@media only screen and (max-width: 1199.98px) {
  .md-sidebar .md-sidebar-toggle {
    display: block;
  }
  .md-sidebar .md-sidebar-aside {
    position: absolute;
    top: 40px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    width: 280px;
  }
  [dir=rtl] .md-sidebar .md-sidebar-aside {
    left: unset;
    right: 0;
  }
  .md-sidebar .md-sidebar-aside.open {
    opacity: 1;
    visibility: visible;
  }
  .md-sidebar .job-sidebar {
    background-color: rgb(240, 240, 240);
    padding: 20px;
  }
  .md-sidebar .job-sidebar .faq-accordion.default-according {
    margin-bottom: 0;
  }
  .md-sidebar .job-sidebar .faq-accordion.default-according .row > div:last-child .card {
    margin-bottom: 0;
  }
  .learning-block > div:first-child {
    order: 2;
  }
  .file-sidebar {
    padding-right: 12px;
  }
  [dir=rtl] .file-sidebar {
    padding-right: 0;
  }
  .col-xl-7.b-center.bg-size {
    top: 0;
    height: 100vh;
    opacity: 0.5;
    position: fixed;
  }
  .login-card {
    background: transparent;
  }
  .knowledgebase-bg {
    height: 400px;
  }
  .knowledgebase-search .form-inline {
    width: 400px;
    padding: 7px 45px;
  }
  .knowledgebase-search .form-inline svg {
    top: 14px;
  }
  .btn-group .btn {
    padding: 0.375rem 1.25rem;
  }
  .link-section > div.active > h6:before {
    content: "\f107";
    transition: content 0.3s ease;
  }
  .link-section > div > h6 {
    position: relative;
  }
  .link-section > div > h6:before {
    content: "\f105";
    font-family: fontAwesome;
    position: absolute;
    right: 10px;
    font-size: 18px;
    transition: content 0.3s ease;
  }
  .alert-theme {
    min-width: 420px;
  }
  .mega-menu-header .header-wrapper .vertical-mobile-sidebar {
    display: block;
    margin-right: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right {
    position: unset;
    padding: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul li .submenu li:first-child {
    padding-left: 15px !important;
  }
  .page-wrapper .page-header .header-wrapper .mobile-sidebar .switch-sm .switch {
    margin-top: 6px;
  }
  .photos ul li {
    width: auto;
  }
  .photos ul li:nth-child(3n) {
    margin-right: 15px;
  }
  .user-profile .hovercard .user-image .share-icons {
    position: absolute;
    right: 45px;
    top: -22px;
  }
  .user-profile .hovercard .user-image .share-icons li {
    color: #fff;
    font-size: 25px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon {
    width: 40px;
    height: 40px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon i {
    font-size: 18px;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    height: 33px;
    bottom: -52px;
  }
  .timeline-small .d-flex .timeline-round.small-line:after {
    height: 18px;
    bottom: -34px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    height: 30px;
    bottom: -52px;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    width: 100%;
  }
  .lg-mt-col {
    margin-top: 30px;
  }
  .xl-none {
    display: none;
  }
  .lg-mt {
    margin-top: 30px;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 32%;
  }
  .debit-card {
    order: 1;
  }
  .typography .row div + div {
    margin-top: 30px;
  }
  .listing .card-body .row > div + div {
    margin-top: 30px;
  }
  .flot-chart-container {
    height: 200px;
  }
  .img-cropper #putData {
    margin-bottom: 10px;
  }
  .img-cropper .docs-toggles > .dropdown {
    margin-bottom: 0;
  }
  .error-wrapper .maintenance-icons li i {
    color: #F3F3F3;
    font-size: 40px;
  }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 80px;
    margin-left: 90px;
  }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    font-size: 120px;
    margin-top: -75px;
    margin-right: 110px;
  }
  div.dataTables_wrapper div.dataTables_paginate ~ .dataTables_filter {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .jvector-map-height {
    height: 280px;
  }
  .blog-list .blog-details p {
    margin-bottom: 5px;
  }
  .custom-card .card-profile .card-footer > div h3 {
    font-size: 18px;
  }
  .equal-height-lg {
    min-height: unset !important;
  }
  .mega-menu .onhover-show-div {
    height: 435px;
    overflow-x: scroll;
    left: 215px;
  }
  .mega-menu .mega-bg {
    display: none;
  }
  .mega-menu .lg-mt {
    margin-top: 25px;
  }
  .button-builder .button-generator-bx {
    position: relative;
    width: 100%;
  }
  .blog-single .comment-box ul .comment-social {
    margin-left: 0;
  }
  [dir=rtl] .blog-single .comment-box ul .comment-social {
    margin-left: unset;
    margin-right: 0;
  }
  .error-wrapper .maintenance-heading {
    margin-top: 30px;
  }
  .error-wrapper .maintenance-heading .headline {
    font-size: 50px;
    margin-top: -15%;
    margin-bottom: 0px;
  }
  .error-wrapper .maintenance-heading .cloud-second {
    margin-top: -25px;
  }
  .testimonial p {
    margin-top: 17px;
  }
  .browser-widget {
    padding: 0px;
  }
  .browser-widget img {
    height: 50px;
  }
  .browser-widget .d-flex .flex-grow-1 h4 {
    font-size: 17px;
  }
  .browser-widget .d-flex .media-img {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .crm-overall .overall-chart {
    height: 200px;
  }
  .product-table table.dataTable thead > tr > th {
    padding-right: 50px;
  }
  .chat-menu-icons {
    display: block;
    clear: both;
  }
  .chat-menu {
    right: 0;
    border-top: 1px solid #EAEAEA;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 60px;
    position: absolute !important;
    z-index: 3;
    background-color: #fff;
    transition: all linear 0.3s;
  }
  .chat-menu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition: all linear 0.3s;
    padding-bottom: 25px;
  }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 444px;
    overflow-y: auto;
  }
  .chat-box .btn-lg {
    font-size: 12px;
  }
  .chat-box .people-list ul {
    height: auto;
  }
  .chat-box .chat-right-aside .chat .chat-header {
    height: auto;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    font-size: 19px;
  }
  .chat-box .chat-right-aside .chat .chat-message {
    margin: 0;
  }
  .chat-box .chat-history .total-time h2 {
    font-size: 25px;
  }
  .chat-box .chat-history .row .col-sm-7 {
    padding-right: 0;
  }
  .chat-box .border-tab.nav-tabs {
    width: 100%;
  }
  .chat-box .border-tab.nav-tabs .nav-item {
    margin-right: 5px;
  }
  .chat-box .chat-left-aside .people-list {
    height: 493px;
  }
  .chat-box .chat-history .call-content > div {
    padding-top: 70px;
  }
  .chat-box .toogle-bar {
    display: inline-block;
    margin-right: 0 !important;
  }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .call-chat-body .chat-box {
    overflow: auto;
    height: auto;
  }
  .email-wrap .email-right-aside .email-body .b-r-light {
    border-right: none !important;
  }
  .email-wrap .email-right-aside .radius-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
  .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-right: 15px;
  }
  .email-wrap .row .col-xl-6 {
    padding-left: 15px;
  }
  .icon-lists div {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 209px;
  }
  .like-comment-lg-mb {
    margin-bottom: 12px;
  }
  .ribbon-space-bottom {
    top: 9px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 98.5% !important;
  }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 95px !important;
  }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 95px !important;
  }
  .profile-img-style #aniimated-thumbnials-3 {
    text-align: center;
  }
  .vertical-menu-mt {
    margin-top: 80px !important;
  }
  .vertical-menu-mt .xl-none {
    display: block;
  }
  .calendar-wrap .fc-day-grid-event {
    width: 87%;
  }
  .product-page-main .product-page-details h3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .page-wrapper.horizontal-wrapper.material-type .page-header {
    max-width: 930px;
  }
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .sidebar-wrapper,
  .page-wrapper.horizontal-wrapper.material-type .page-body-wrapper .page-body {
    max-width: 930px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-left: 0;
  }
}
@media only screen and (max-width: 991.98px) {
  .todo-list-wrapper #todo-list li .task-container .task-label {
    min-width: 460px;
  }
  .project-cards .tab-content {
    margin-bottom: -20px;
  }
  .project-box {
    margin-bottom: 20px;
  }
  .page-wrapper.modern-type .simplebar-mask {
    top: 70px;
  }
  .page-wrapper.compact-sidebar .simplebar-mask {
    top: 87px;
  }
  .page-wrapper.compact-sidebar .page-header {
    margin-left: 0;
    width: calc(100% - 0px);
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links li .sidebar-link.active ~ .sidebar-submenu {
    display: none !important;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper .page-body {
    margin-top: 60px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .search-full .Typeahead-menu {
    top: 65px;
  }
  .page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper {
    position: fixed !important;
    top: 0 !important;
  }
  .product-box .modal .modal-header .product-box .product-details {
    padding-left: 15px;
    padding-right: 15px;
  }
  .search-form .form-group:before {
    top: 33px;
  }
  .search-form .form-group:after {
    top: 35px;
  }
  .mobile-title.d-none {
    padding: 11px 30px 10px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
    top: 50px;
  }
  .page-wrapper .page-header .header-wrapper .search-full input {
    line-height: 50px;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
    top: 20px;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group:before {
    top: 18px;
  }
  .page-wrapper .current_lang .lang span.lang-txt {
    display: none;
  }
  .customizer-contain {
    top: 62px;
  }
  .m-r-30 {
    margin-right: 20px;
  }
  .card.full-card {
    top: 60px;
    width: calc(100vw - 0px);
  }
  .vertical-mobile-sidebar {
    right: 75px;
    top: 18px;
  }
  .gallery {
    margin-bottom: -20px;
  }
  .my-gallery figure {
    margin-bottom: 20px;
  }
  #aniimated-thumbnials figure:nth-child(12), #aniimated-thumbnials figure:nth-child(11), #aniimated-thumbnials figure:nth-child(10), #aniimated-thumbnials figure:nth-child(9) {
    margin-bottom: 20px;
  }
  .photoswipe-pb-responsive {
    padding-bottom: 25px !important;
  }
  table.dataTable.fixedHeader-locked, table.dataTable.fixedHeader-floating {
    left: 0 !important;
    width: 100% !important;
  }
  .page-wrapper .section-space {
    padding: 60px 0;
  }
  .page-wrapper .card .card-header .tab-content .m-t-30,
  .page-wrapper .card .card-body .tab-content .m-t-30,
  .page-wrapper .card .card-footer .tab-content .m-t-30 {
    margin-top: 20px !important;
  }
  .page-wrapper .card .card-header .tab-content .m-b-30,
  .page-wrapper .card .card-body .tab-content .m-b-30,
  .page-wrapper .card .card-footer .tab-content .m-b-30 {
    margin-bottom: 20px !important;
  }
  .page-wrapper .page-body-wrapper .footer-fix {
    margin-left: 0px;
    padding-right: 15px;
    width: calc(100% - 0px) !important;
  }
  .page-wrapper.compact-wrapper .page-header {
    margin-left: 0 !important;
    width: calc(100% - 0px) !important;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .maximize {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .header-logo-wrapper {
    display: flex;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
    display: block !important;
    width: 40px;
    height: 40px;
    background-color: rgba(48, 126, 243, 0.2);
    position: unset;
    border-radius: 10px;
    margin-right: 10px;
  }
  /*Form Wizard One start*/
  /*Form Wizard One ends*/
  /*Form Wizard Three Start*/
  /*Form Wizard Three ends*/
  /*Form Wizard Four Start*/
  /*Form Wizard Four ends*/
}
@media only screen and (max-width: 991.98px) and (max-width: 630px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
    width: 35px;
    height: 35px;
  }
}
@media only screen and (max-width: 991.98px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar svg {
    width: 20px;
    height: 20px;
    transform: translate(50%, -50%);
    font-size: 18px;
    position: absolute;
    top: 50%;
    stroke: #363636;
  }
  [dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar svg {
    left: 15px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 575px) {
  [dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar svg {
    left: 7px;
  }
}
@media only screen and (max-width: 991.98px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar:before {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-nav {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
    top: 0;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover {
    transform: translate(-285px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper {
    padding: 17px 30px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper .back-btn {
    display: inline-block;
    float: right;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 95px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
    transform: translate(-285px);
  }
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
    height: unset;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-top: 60px;
    padding: 0 15px;
  }
  .page-wrapper.compact-sidebar .page-header .header-wrapper .maximize {
    display: none;
  }
  .page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
    display: none;
    margin-right: 0;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper {
    margin: 0 !important;
  }
  .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-wrapper {
    display: block;
  }
  .page-wrapper.material-icon .page-header {
    padding-left: 0;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-body {
    padding-left: 15px;
  }
  .page-wrapper .page-header .header-wrapper {
    margin: 0;
    padding: 12px 30px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification {
    top: 10px;
  }
  .page-wrapper .page-header .header-wrapper > .mobile-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .page-wrapper .page-header .header-wrapper .header-logo-wrapper {
    margin-right: 0;
  }
  .page-wrapper .page-header .header-logo-wrapper .logo-wrapper img {
    margin-top: 0;
  }
  .page-body-wrapper .page-body,
  .page-body-wrapper footer {
    margin-left: 0 !important;
  }
  .page-body-wrapper .page-body #customer-review .owl-stage-outer,
  .page-body-wrapper footer #customer-review .owl-stage-outer {
    width: 100% !important;
  }
  .default-according .card {
    margin-bottom: 10px !important;
  }
  .default-according .card .card-header {
    padding: 0 !important;
  }
  .faq-wrap .faq-accordion.default-according .card .faq-header {
    padding: 20px 20px 0 !important;
  }
  .crm-activity > li + li {
    margin-top: 10px;
    padding-top: 10px;
  }
  .add-post .dropzone {
    margin-bottom: 20px;
  }
  .customers.avatar-group {
    margin-right: 20px;
  }
  .socialprofile .social-btngroup {
    margin: 20px 0;
  }
  .socialprofile .social-group {
    margin-top: 20px;
  }
  .social-status form .form-group .form-control-social {
    margin-bottom: 20px;
  }
  .social-status .d-flex {
    margin-bottom: 20px;
  }
  .timeline-content p,
  .timeline-content .comments-box {
    margin-top: 20px;
  }
  .social-chat {
    margin-top: 20px;
  }
  .social-chat .flex-grow-1 {
    padding: 20px;
  }
  .social-chat .your-msg,
  .social-chat .other-msg {
    margin-bottom: 20px;
  }
  .social-network span {
    margin-bottom: 20px;
  }
  .details-about + .details-about {
    margin-top: 20px;
  }
  .activity-log .my-activity + .my-activity {
    margin-top: 20px;
  }
  .new-users-social {
    margin-bottom: 20px;
  }
  .timeline-small .d-flex {
    margin-bottom: 20px;
  }
  .timeline-small .d-flex .flex-grow-1 h6 {
    margin-bottom: 20px;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    width: 75%;
  }
  .timeline-small .d-flex .timeline-round {
    width: 45px;
    height: 45px;
    top: -14px;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -41px;
    height: 25px;
  }
  .timeline-small .d-flex .timeline-round.small-line:after {
    bottom: -26px;
    height: 14px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    bottom: -33px;
    height: 17px;
  }
  .timeline-small .d-flex .timeline-round svg {
    top: 14px;
  }
  .helper-classes {
    padding: 20px;
  }
  .floated-customizer-btn,
  .floated-customizer-panel {
    display: none;
  }
  .page-builder .ge-canvas.ge-editing .row {
    padding: 20px;
  }
  .ecommerce-widget .progress-showcase {
    margin-top: 20px;
  }
  .crm-overall {
    margin: 0 -20px -20px;
  }
  .product-page-main {
    padding: 20px;
  }
  .grid-options ul li:nth-child(3) {
    display: none;
  }
  .feature-products form .form-group i {
    right: 20px;
  }
  .tilt-showcase .tilt-image {
    text-align: center;
  }
  .tilt-showcase .mt-4 {
    margin-top: 20px !important;
  }
  .todo .todo-list-wrapper .mark-all-tasks {
    top: 20px;
    right: 20px;
  }
  .custom-card {
    padding-bottom: 0;
  }
  .custom-card .card-footer {
    padding: 0 15px 15px 15px !important;
  }
  .custom-card .card-header {
    border: none;
  }
  .custom-card .dashboard-card {
    padding: 0;
  }
  .custom-card .card-profile img {
    height: 155px;
  }
  .display-1 {
    font-size: calc(30px + 34 * (100vw - 320px) / 880);
  }
  .display-2 {
    font-size: calc(25px + 31 * (100vw - 320px) / 880);
  }
  .display-3 {
    font-size: calc(20px + 20 * (100vw - 320px) / 880);
  }
  .display-4 {
    font-size: calc(15px + 9 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h1 {
    font-size: calc(16px + 18 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h2 {
    font-size: calc(18px + 12 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h3 {
    font-size: calc(17px + 5 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h4 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h4 {
    font-size: calc(15px + 5 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h5 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h5 {
    font-size: calc(13px + 5 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  .typography .h6 {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991.98px) and (max-width: 1200px) {
  .typography .h6 {
    font-size: calc(12px + 3 * (100vw - 320px) / 880);
  }
}
@media only screen and (max-width: 991.98px) {
  h1 {
    font-size: calc(20px + 14 * (100vw - 320px) / 880);
  }
  h2 {
    font-size: calc(18px + 10 * (100vw - 320px) / 880);
  }
  h3 {
    font-size: calc(17px + 9 * (100vw - 320px) / 880);
  }
  h4 {
    font-size: calc(17px + 5 * (100vw - 320px) / 880);
  }
  .card-absolute .card-header {
    padding: 10px 15px !important;
  }
  .comingsoon .comingsoon-inner .countdown .time {
    width: 80px;
    height: 80px;
    font-size: 26px;
  }
  .calendar-wrap .basic-calendar .external-events .fc-event + .fc-event {
    margin-top: 10px;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-bottom: -10px;
    margin-top: 15px;
  }
  .card .card-header h5 {
    font-size: 18px;
  }
  .card .card-header .card-header-right {
    top: 12px;
    right: 15px;
  }
  .page-builder .ge-addRowGroup {
    width: 100%;
  }
  .user-profile .hovercard .info .user-designation {
    margin-bottom: 20px;
  }
  .search-list {
    justify-content: center;
  }
  .blog-single .comment-box .comment-social li {
    padding-left: 15px;
  }
  .error-wrapper {
    padding: 35px 0;
  }
  .error-wrapper .sub-content {
    font-size: 14px;
    line-height: 25px;
    margin-top: 0px;
  }
  .error-wrapper .error-heading {
    margin-top: 30px;
  }
  .error-wrapper .error-heading .headline {
    font-size: 180px;
    margin-top: 0;
  }
  .error-wrapper .maintenance-heading {
    margin-top: 30px;
  }
  .editor_container .CodeMirror {
    min-height: 250px;
    height: 250px;
  }
  .search-page .info-block {
    flex-wrap: wrap;
  }
  .search-page .tab-content .pb-4 {
    padding-bottom: 20px !important;
  }
  .xl-none {
    display: block;
  }
  .top-radius-blog {
    width: 100%;
  }
  .sm-100-w {
    width: 100%;
    height: 100%;
  }
  .form-wizard .btn-mb {
    margin-bottom: 20px;
  }
  .f1 .f1-steps {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .wizard-4 ul.anchor {
    padding-right: 20px;
    width: 40%;
  }
  .wizard-4 ul.anchor li a.done h4:before {
    top: -8px;
  }
  .wizard-4 ul.anchor li h4 {
    padding: 11px 18px;
    width: 50px;
  }
  .wizard-4 ul.anchor li + li a {
    padding-left: 65px;
  }
  [dir=rtl] .wizard-4 ul.anchor li + li a {
    padding-right: 65px;
  }
  .wizard-4 .step-container {
    width: 60%;
  }
  .wizard-4 .step-container div.content .wizard-title {
    width: 380px;
  }
  .wizard-4 .login-card .login-main {
    width: 380px;
  }
  .wizard-4 .msg-box {
    top: 25px !important;
  }
  .border-tab.nav-tabs {
    margin-bottom: 20px;
  }
  .tab-content ~ .nav-tabs.border-tab {
    margin-top: 20px;
  }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px;
  }
  .dataTables_wrapper table.dataTable {
    margin-bottom: 20px !important;
  }
  .search-page .info-block + .info-block {
    padding-top: 20px;
    margin-top: 20px;
  }
  .search-page #video-links .embed-responsive + .embed-responsive {
    margin-top: 20px;
  }
  .lg-mt {
    margin-top: 20px;
  }
  .gallery-with-description {
    margin-bottom: -20px;
  }
  .gallery-with-description a > div {
    margin-bottom: 20px;
  }
  .clipboaard-container p {
    margin-bottom: 8px;
  }
  .m-b-30 {
    margin-bottom: 20px !important;
  }
  .email-wrap .email-left-aside .email-app-sidebar ul li hr {
    margin: 13px 0;
  }
  .nav-md-mt {
    margin-top: 20px;
  }
  .navs-icon {
    padding: 20px;
  }
  .navs-icon .main-section {
    padding-top: 20px;
  }
  .navs-icon .separator {
    margin: 20px 0;
  }
  .nav-list {
    padding: 20px;
  }
  .navs-dropdown button {
    margin-top: 20px;
  }
  .navs-dropdown .onhover-show-div {
    top: 68px;
  }
  .animated-modal .form-group {
    width: 100%;
    display: block;
  }
  .animated-modal .mr-4 {
    margin-right: 0 !important;
  }
  .steps-md-mt {
    margin-top: 20px;
  }
  .btn-group-wrapper .m-b-30:last-child {
    margin-bottom: 0 !important;
  }
  #advance-5_wrapper .dataTables_paginate {
    margin-bottom: 20px;
  }
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 20px;
  }
  .chat-box .chat-left-aside .people-list {
    height: 483px;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
    margin-right: 5px;
  }
  .card .card-body form.animated-modal {
    display: flex;
  }
  .card .card-body form.animated-modal .animated-modal-md-mb {
    margin-bottom: 20px !important;
  }
  .wizard-4 .msg-box {
    top: 40px;
    right: 0%;
    bottom: unset;
    left: unset;
  }
  .theme-form.form-inline {
    display: flex;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .page-wrapper.compact-wrapper.material-type::before {
    height: 230px;
  }
  .page-wrapper.compact-wrapper.material-type .page-header {
    width: calc(100% - 60px) !important;
    margin: 50px auto 0 !important;
  }
  .page-wrapper.compact-wrapper.material-type .page-body-wrapper {
    margin: 0 30px;
  }
  .page-wrapper.compact-wrapper.material-type .page-body-wrapper div.sidebar-wrapper {
    left: 0;
  }
  .page-wrapper.compact-wrapper.material-type .page-body-wrapper .page-body {
    margin-top: 0;
  }
}
@media only screen and (max-width: 810px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li .profile-media .flex-grow-1 {
    display: none;
  }
}
@media only screen and (max-width: 767.98px) {
  .wizard-4 ul.anchor {
    width: 100%;
    height: auto;
    padding: 30px;
  }
  .wizard-4 .step-container {
    width: 100%;
  }
  .contact-editform {
    padding-left: 20px !important;
  }
  .event-calendar {
    display: block !important;
  }
  .event-calendar #right {
    width: 100%;
  }
  .event-calendar #calendar {
    clear: both;
  }
  .file-content .d-md-flex {
    text-align: center;
  }
  .file-content .d-md-flex .flex-grow-1 {
    margin-top: 15px;
    text-align: center !important;
  }
  .page-wrapper.material-type::before {
    display: none;
  }
  .page-wrapper.material-type .page-header {
    margin-top: 0;
    border-radius: 0;
  }
  .page-wrapper.material-type .page-body-wrapper .page-body {
    margin-top: 0 !important;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right > ul > li {
    margin-right: -2px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right .nav-menus {
    margin-right: -10px;
  }
  .new-products .owl-carousel .product-box.row > div {
    width: auto;
  }
  .profile-media .flex-grow-1 {
    display: none;
  }
  .form-inline .form-group:not(.form-control-search) {
    width: 100%;
  }
  .form-inline .form-group:not(.form-control-search) + .form-group {
    margin-top: 10px;
  }
  .form-inline .form-group:not(.form-control-search) input {
    width: 100%;
  }
  .credit-card .text-center img {
    margin-bottom: 20px;
  }
  .starter-kit-fix .page-body {
    margin-bottom: 78px;
  }
  .blog-box .blog-details-main .blog-social li {
    padding: 0 12px;
  }
  .blog-box .blog-details .blog-social li:first-child {
    padding-right: 25px;
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 25px;
  }
  .footer-copyright ~ div {
    text-align: center;
  }
  .footer-copyright ~ div .heart {
    text-align: center;
    margin-top: 5px;
  }
  .user-profile .hovercard .user-image .share-icons {
    right: 25px;
    top: -19px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon {
    width: 35px;
    height: 35px;
  }
  .tabs-scoial.border-tab.nav-tabs {
    display: flex;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    width: 100%;
  }
  .my-gallery figure:nth-child(9), .my-gallery figure:nth-child(10) {
    margin-bottom: 20px;
  }
  .project-list .btn {
    float: left;
    margin-top: 5px;
  }
  [dir=rtl] .project-list .btn {
    float: right;
    margin-right: unset;
    margin-left: 5px;
  }
  .chat-box .chat-right-aside .chat .chat-message .text-box .btn {
    font-size: 0;
  }
  .chat-box .chat-right-aside .chat .chat-message .text-box .btn:before {
    position: absolute;
    content: "\e661";
    font-family: themify;
    font-size: 18px;
    top: 11px;
    left: 20px;
  }
  .chat-box .border-tab.nav-tabs {
    display: flex;
  }
  .chat-box .chat-menu .nav-tabs .nav-item a {
    height: 50px;
  }
  .chat-box .chat-history .call-content > div {
    padding-top: 40px;
    height: auto;
  }
  .chat-box .chat-history .receiver-img {
    margin-bottom: unset;
  }
  .chat-box .chat-left-aside .people-list {
    height: auto;
  }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
  }
  .call-chat-sidebar .people-list {
    height: auto;
  }
  .chat-left-aside .people-list ul {
    max-height: 100px;
  }
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important;
  }
  .clockpicker-align-top .arrow {
    display: none;
  }
  .form-builder-column .form-body {
    min-height: 250px;
  }
  .form-builder-2-header {
    flex-wrap: wrap;
  }
  .form-builder-2-header > div {
    width: 100%;
    margin-top: 10px;
  }
  .form-builder-2-header > div nav {
    float: none;
  }
  .lg-mt-col {
    margin-top: 20px;
  }
  .form-builder .drag-box fieldset {
    padding: 20px;
    margin-top: 20px;
  }
  .form-builder #components {
    margin-top: 20px;
  }
  .form-builder .component label {
    padding-top: 0px;
  }
  .mega-menu .onhover-show-div {
    height: 415px;
    left: 0;
    top: 61px;
    padding: 20px;
  }
  .mega-menu .lg-mt {
    margin-top: 15px;
  }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer {
    margin: 10px 0 0 10px;
  }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer > a {
    padding: 0 5px;
  }
  .reader {
    margin-top: 15px;
    height: auto !important;
  }
  .range-slider.theme-form .form-group {
    margin-bottom: 0;
  }
  .note-editor .btn-group .btn {
    font-size: 11px;
  }
  .text-md-right {
    text-align: right;
  }
  .star-ratings .stars {
    padding: 0;
  }
  .form-inline.d-inline-block {
    display: block !important;
    width: 100%;
  }
  .form-inline.d-inline-block .form-control {
    margin-bottom: 10px;
  }
  .reset-password-box {
    width: 500px;
    margin: 0 auto;
  }
  .reset-password-box .card {
    padding: 20px;
  }
  .custom-card .card-profile img {
    height: 136px;
    bottom: 0;
  }
  .error-wrapper .maintenance-icons li i {
    color: #F3F3F3;
    font-size: 30px;
  }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 60px;
    margin-top: -10px;
    margin-left: 70px;
  }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    font-size: 90px;
    margin-right: 80px;
  }
  .authentication-main .auth-innerright {
    min-height: unset;
    padding: 0;
  }
  .authentication-main .auth-innerright .social-media li {
    font-size: 14px;
  }
  .loader-box {
    justify-content: center;
  }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }
  div.dataTables_wrapper div.dataTables_info {
    padding-top: 0;
  }
  div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_filter label {
    float: none;
  }
  div.dataTables_wrapper div.dataTables_paginate {
    float: none;
    margin-top: 20px !important;
    margin-left: 0 !important;
  }
  .calendar-wrap .fc-basic-view .fc-day-number {
    padding: 0 0px !important;
  }
  .calendar-wrap .fc-events-container {
    margin-top: 0px;
  }
  .calendar-wrap .fc-events-container h6,
  .calendar-wrap .fc-events-container .checkbox {
    text-align: right;
  }
  .calendar-wrap .checkbox label {
    margin-top: 0;
  }
  .calendar-wrap .fc-toolbar .fc-left .fc-next-button .fc-icon-right-single-arrow:after {
    font-size: 12px !important;
  }
  .calendar-wrap .fc-toolbar .fc-left .fc-prev-button .fc-icon-left-single-arrow:after {
    font-size: 12px !important;
  }
  .calendar-wrap .fc-toolbar .fc-left .fc-today-button,
  .calendar-wrap .fc-toolbar .fc-right .fc-today-button {
    padding-top: 0px !important;
  }
  .calendar-wrap .fc {
    margin-top: 0px;
  }
  .calendar-wrap .fc button {
    font-size: 14px !important;
    padding: 0 5px !important;
  }
  .calendar-wrap .fc .fc-left {
    margin-bottom: 10px;
  }
  .calendar-wrap .fc .fc-toolbar > div {
    display: block !important;
    float: none !important;
  }
  .calendar-wrap .fc .fc-toolbar > * :first-child {
    font-size: 18px;
  }
  .calendar-wrap .fc .fc-toolbar > * > * {
    float: none !important;
  }
  .btn-group-wrapper {
    text-align: center;
  }
  .btn-group-wrapper .m-b-30 {
    margin-bottom: 15px !important;
  }
  .btn-group-showcase {
    text-align: center;
  }
  .btn-group-showcase .btn-group {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .btn-group .btn {
    font-size: 14px;
    padding: 0.375rem 1.3rem;
  }
  .animate-widget .text-center {
    display: none;
  }
  .mega-menu .onhover-show-div {
    height: 350px;
  }
  .page-builder .ge-canvas .column {
    padding: 0 !important;
  }
  .page-builder .ge-content-type-ckeditor h3 {
    font-size: 20px;
  }
  .page-builder .ge-content-type-ckeditor p {
    font-size: 12px;
  }
  .page-builder .ge-row-icon {
    display: none !important;
  }
  /* font-awesom icon page */
  .icon-hover-bottom .form-group input {
    min-width: 270px;
  }
  .user-profile .hovercard .cardheader {
    height: 350px;
  }
  .user-profile .hovercard .info {
    padding: 35px;
  }
  .user-profile .hovercard .info .ttl-info {
    margin-bottom: 20px;
  }
  .user-profile .hovercard .info .ttl-sm-mb-0 {
    margin-bottom: 0;
  }
  .calendar-wrap .fc button {
    padding: 5px;
  }
  .calendar-wrap .fc-day-grid-event {
    margin: 0;
    padding: 5px;
    width: inherit;
  }
  .calendar-wrap .fc-toolbar h2 {
    font-size: 20px;
    line-height: 35px;
    font-weight: bold;
  }
  .calendar-wrap .basic-calendar .external-events {
    margin-top: 0px;
  }
  .blog-single .comment-box .d-flex h6 {
    margin-bottom: 10px;
  }
  .blog-single .comment-box .d-flex img {
    margin-right: 30px;
  }
  .blog-single .comment-box .comment-social {
    margin-bottom: 5px;
  }
  .blog-single .comment-box .comment-social li:first-child {
    padding-left: 0;
    padding-right: 15px;
  }
  .search-page #image-links .info-block.m-t-30 {
    margin-top: 0 !important;
  }
  .authentication-main .authentication-box {
    width: 100%;
  }
  .authentication-main .auth-innerright {
    display: inherit;
  }
  .map-chart .chart-container {
    height: 300px;
  }
  .candidcahrt {
    height: 250px;
  }
  .bar-chart-widget .earning-details i {
    right: 0;
  }
  .serial-chart .chart-container {
    height: 300px;
  }
  .bottom-content {
    padding: 10px;
  }
  .bottom-content p {
    font-size: 12px;
  }
  .icon-hover-bottom .icon-popup {
    display: block;
  }
  .icon-hover-bottom .icon-popup > .icon-last {
    display: block;
    margin-top: 15px;
  }
  .icon-hover-bottom .icon-popup > .icon-last .form-inline .form-group {
    display: flex;
  }
  .icon-hover-bottom .icon-popup div .flag-icon {
    width: 30px;
    height: auto;
  }
  .icon-hover-bottom .form-group input {
    display: block;
    width: auto !important;
  }
  .footer-links {
    text-align: center;
  }
  .footer-copyright {
    text-align: center !important;
  }
  .jvector-map-height {
    height: 150px;
  }
  .sm-left-text {
    text-align: left !important;
  }
  [dir=rtl] .sm-left-text {
    text-align: right !important;
  }
  .email-wrap .email-right-aside .email-content .email-top .d-flex {
    padding-bottom: 0;
  }
  .email-wrap .email-right-aside .email-content .email-top .float-right {
    float: left !important;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment {
    text-align: center;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li:last-child {
    padding-right: 0;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 140px;
    height: 140px;
  }
  .alert-dismissible p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 294px;
  }
  .button-group-mb-sm {
    margin-bottom: 5px;
  }
  .card-block .table-responsive .table tbody tr td span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px;
  }
  .card-block .table-responsive .progress-content tbody tr td span {
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 50px;
  }
  .card-block .table-responsive .checkbox-td-width tbody tr td,
  .card-block .table-responsive .radio-first-col-width tbody tr td {
    min-width: 200px !important;
  }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 119px !important;
  }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 119px !important;
  }
  .jsgrid .jsgrid-pager-container {
    text-align: center;
  }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: auto;
  }
  .chat-box .chat-menu .nav-tabs .nav-item {
    width: 32.33%;
  }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 433px;
    margin-bottom: 15px;
  }
  .chat-box .chat-menu #info-profile,
  .chat-box .chat-menu #info-contact {
    margin-bottom: 15px;
  }
  .hovercard .info .follow .text-md-right {
    text-align: center;
  }
  .profile-img-style .like-comment-sm-mb {
    margin-bottom: 20px;
  }
  .reset-password-box .theme-form .form-group .btn {
    margin-top: 15px !important;
  }
  .lg-outer .lg-actions .lg-next,
  .lg-outer .lg-actions .lg-prev {
    top: 48%;
  }
  .feature-products .filter-toggle {
    padding: 8px 15px;
    background-color: #fff;
    margin-left: 10px;
    border-radius: 5px;
  }
  .feature-products .select2-drpdwn-product {
    float: left;
    margin-right: 10px;
    margin-top: 8px;
  }
  [dir=rtl] .feature-products .select2-drpdwn-product {
    float: right;
    margin-right: unset;
    margin-left: 10px;
  }
  .feature-products span.f-w-600 {
    padding-top: 15px;
    display: inline-block;
  }
  .feature-products .text-right {
    text-align: left !important;
  }
  .d-none-productlist {
    display: block;
    margin-right: 10px;
  }
  .grid-options ul li {
    display: none;
  }
  .product-sidebar {
    transform: translateX(-300px) scaleX(0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 0;
    position: absolute;
    z-index: 3;
    width: 300px;
  }
  .product-sidebar.open {
    transform: translateX(0px) scaleX(1);
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    visibility: visible;
    opacity: 1;
    height: auto;
    top: -45px;
  }
  .product-sidebar.open:before, .product-sidebar.open:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    right: 120px;
    top: -7px;
  }
  .product-sidebar .filter-section .card-header {
    display: none;
  }
  .product-wrapper.sidebaron .product-grid .product-wrapper-grid {
    margin-left: 0;
  }
  .product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
    top: unset;
    min-width: unset;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 42%;
  }
  .product-filter .product-box {
    flex-wrap: nowrap;
  }
  .product-filter .product-box .product-img {
    width: 100px;
  }
  .product-filter .product-box .product-details {
    padding: 0 !important;
  }
}
@media only screen and (max-width: 575.98px) {
  .todo .todo-list-wrapper .mark-all-tasks .mark-all-tasks-container {
    display: none;
  }
  .border-tab.nav-tabs {
    display: block;
  }
  .border-tab.nav-tabs .nav-item .nav-link {
    padding: 5px 15px 5px 0 !important;
  }
  .page-wrapper .page-header .header-wrapper .search-full input {
    padding-left: 30px;
    line-height: 40px;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group::before {
    top: 14px;
    left: 12px;
  }
  .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
    top: 18px;
    right: 0;
    width: 18px;
    height: 18px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right {
    right: 30px;
  }
  .date-picker .theme-form > .row > div:last-child {
    margin-top: 0;
  }
  .search-page .info-block {
    display: block;
  }
  .form-bookmark .row > div .row > .col-sm-6 + .col-sm-6 {
    margin-top: 10px;
  }
  .btn {
    padding: 6px 16px;
  }
  .alert-theme {
    min-width: 380px;
  }
  .knowledgebase-bg {
    height: 250px;
  }
  .login-card .login-main .theme-form .or:before {
    width: 55%;
  }
  .knowledgebase-search {
    width: calc(100% - 30px);
    left: 15px;
    height: calc(100% - 30px);
    background-color: rgba(255, 255, 255, 0.7);
  }
  .knowledgebase-search .form-inline {
    width: 90%;
    padding: 3px 45px;
  }
  .knowledgebase-search .form-inline svg {
    top: 10px;
  }
  .knowledgebase-search > div {
    width: 100%;
    text-align: center;
    padding: 15px;
    border-right: 10px;
  }
  .knowledgebase-search > div h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .knowledgebase-search > div .form-group {
    margin-bottom: 0;
  }
  .invoice .text-md-end {
    margin-top: 10px;
  }
  .invoice .text-right {
    text-align: right;
  }
  .invoice .text-xs-center {
    text-align: center;
  }
  .login-card {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .login-card .logo {
    margin-bottom: 10px;
  }
  .login-card .login-main {
    width: auto;
    padding: 20px;
  }
  .login-card .login-main .theme-form .form-group {
    margin-bottom: 5px;
  }
  .login-card .login-main .theme-form p {
    margin-bottom: 5px;
  }
  .login-card .btn-showcase .btn {
    width: 100%;
  }
  .login-card .btn-showcase .btn + .btn {
    margin-left: 0;
    margin-top: 5px;
  }
  .bookmark ul li a svg {
    height: 16px;
    width: 16px;
  }
  .bookmark ul li + li {
    margin-left: 5px;
  }
  .page-header .header-wrapper .nav-right .cart-box .badge,
  .page-header .header-wrapper .nav-right .notification-box .badge {
    font-size: 10px;
  }
  .page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child {
    display: block;
  }
  .page-wrapper .page-body-wrapper .page-title > .row .col-6:first-child h3 {
    padding-right: 0;
  }
  .page-wrapper .page-header .header-wrapper .nav-right ul li i {
    font-size: 14px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right ul li svg {
    width: 18px;
  }
  .page-wrapper .page-body-wrapper .page-title .breadcrumb {
    margin-top: 8px;
    justify-content: flex-start;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .logo-wrapper, .page-wrapper.compact-sidebar .page-header .header-wrapper .logo-wrapper {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
    border-right: 1px solid #ddd;
    padding-right: 8px;
    margin-right: 8px;
  }
  /* alert responsive css start here */
}
@media only screen and (max-width: 575.98px) and (max-width: 630px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
    margin-right: 4px;
  }
}
@media only screen and (max-width: 575.98px) {
  .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar svg, .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar svg {
    width: 18px;
    stroke: #363636;
  }
  .page-wrapper.compact-sidebar .sidebar-wrapper .back-btn {
    display: none;
  }
  .page-wrapper.compact-sidebar .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    margin-right: 0;
  }
  .page-wrapper .page-header .header-wrapper {
    padding: 12px 15px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul {
    top: 58px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul .search-form i {
    display: none;
  }
  .page-wrapper .page-header .header-wrapper .nav-right.right-header ul li.px-0 {
    padding: 6px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul li .flag-icon {
    font-size: 14px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul li .profile-media img {
    width: 25px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown.onhover-show-div:before, .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown.onhover-show-div:after {
    left: 24px !important;
    right: unset !important;
  }
  .page-wrapper .page-body-wrapper .page-title .btn-group .btn {
    padding: 8px 30px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin-top: 53px;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 79px);
  }
  .alert-dismissible {
    padding-right: 30px;
  }
  .alert-dismissible p {
    max-width: 102px;
  }
  .customizer-links {
    display: none;
  }
  .m-r-30 {
    margin-right: 15px;
  }
  .wishlist .text-end {
    text-align: left !important;
  }
  .add-post form .m-checkbox-inline label {
    margin-right: 15px;
  }
  .add-post .dropzone {
    margin-bottom: 15px;
  }
  .wizard-4 .msg-box {
    top: 10px !important;
  }
  .wizard-4 .login-card .login-main {
    width: 280px;
  }
  .wizard-4 .step-container div.content .wizard-title {
    width: 280px;
  }
  .wizard-4 .action-bar {
    right: 10px;
  }
  .wizard-4 .action-bar [dir=rtl] {
    left: 10px;
  }
  .customers.avatar-group {
    margin-right: 15px;
  }
  .vertical-mobile-sidebar {
    top: 19px;
  }
  .alert-theme i {
    margin-right: 10px !important;
  }
  .alert-theme button {
    top: 0px !important;
  }
  .bar-chart-widget .earning-details i {
    right: -30px;
  }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important;
  }
  .gallery {
    margin-bottom: -15px;
  }
  .my-gallery figure {
    margin-bottom: 15px;
  }
  .my-gallery figure:nth-child(9), .my-gallery figure:nth-child(10) {
    margin-bottom: 15px;
  }
  .my-gallery.gallery-with-description figure:last-child {
    margin-bottom: 20px;
  }
  #aniimated-thumbnials figure:nth-child(12), #aniimated-thumbnials figure:nth-child(11), #aniimated-thumbnials figure:nth-child(10), #aniimated-thumbnials figure:nth-child(9) {
    margin-bottom: 15px;
  }
  .photoswipe-pb-responsive {
    padding-bottom: 30px !important;
  }
  .avatar-showcase .pepole-knows {
    text-align: center;
  }
  .avatar-showcase .pepole-knows ul li {
    margin-right: 14px;
  }
  .social-app-profile .hovercard .user-image .avatar {
    margin-top: -56px;
  }
  .social-app-profile .hovercard .user-image .avatar img {
    width: 100px;
    height: 100px;
  }
  .social-app-profile .hovercard .user-image .icon-wrapper {
    left: 52%;
    top: 10px;
    height: 30px;
    width: 30px;
    font-size: 15px;
  }
  .social-app-profile .hovercard .user-image .icon-wrapper:hover {
    font-size: 12px;
  }
  .social-app-profile .hovercard .user-image .share-icons {
    right: 15px;
  }
  .social-app-profile .hovercard .user-image .share-icons li {
    margin-right: 0px;
  }
  .social-app-profile .hovercard .info .tabs-scoial .user-designation {
    border-top: none;
    border-bottom: none;
    margin-bottom: 0;
    padding: 0px 0px;
  }
  .social-app-profile .tabs-scoial {
    margin-bottom: 0 !important;
  }
  .socialprofile .social-btngroup {
    margin: 15px 0;
  }
  .socialprofile .social-group {
    margin-top: 15px;
  }
  .social-status form .form-group .form-control-social {
    margin-bottom: 15px;
  }
  .social-status .d-flex {
    margin-bottom: 15px;
  }
  .new-users-social {
    margin-bottom: 15px;
  }
  .tabs-scoial {
    position: relative;
    padding-top: 80px;
  }
  .tabs-scoial li:nth-child(3) {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .tabs-scoial.border-tab.nav-tabs .nav-item .nav-link {
    padding: 10px;
    height: 60px;
  }
  .timeline-content p,
  .timeline-content .comments-box {
    margin-top: 15px;
  }
  .timeline-content .comments-box .d-flex .m-r-20 {
    margin-right: 10px;
  }
  .timeline-content .comments-box .d-flex .flex-grow-1 .input-group .input-group-append .btn {
    padding: 6px;
  }
  .timeline-content .comment-number i {
    margin-right: 15px;
  }
  #cd-timeline {
    margin-right: 0;
  }
  .social-chat {
    margin-top: 15px;
  }
  .social-chat .flex-grow-1 {
    padding: 15px;
  }
  .social-chat .your-msg,
  .social-chat .other-msg {
    margin-bottom: 15px;
  }
  .social-network span {
    margin-bottom: 15px;
  }
  .social-list .d-flex {
    margin-bottom: 15px;
  }
  .details-about + .details-about {
    margin-top: 15px;
  }
  .your-details-xs {
    margin-top: 15px;
  }
  .social-header h5 span.pull-right {
    float: right !important;
    margin-top: 0 !important;
  }
  .social-header h5 span.pull-right svg {
    width: 18px;
    height: 18px;
  }
  .activity-log .my-activity + .my-activity {
    margin-top: 15px;
  }
  .photos ul li {
    width: 26%;
  }
  .photos ul li:nth-child(3n) {
    margin-right: 0;
  }
  .chat-box .chat-history .call-content > div {
    padding-top: 30px;
  }
  .timeline-small .d-flex {
    margin-bottom: 15px;
  }
  .timeline-small .d-flex .flex-grow-1 h6 {
    margin-bottom: 15px;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -39px;
    height: 30px;
  }
  .timeline-small .d-flex .timeline-round.small-line:after {
    bottom: -17px;
    height: 8px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    bottom: -30px;
    height: 22px;
  }
  .helper-classes {
    padding: 15px;
  }
  .form-builder-2-header ul li {
    text-align: left;
  }
  .form-builder-2-header .form-inline {
    display: block;
    width: 100%;
  }
  .form-builder-2-header .form-inline .form-group {
    width: 100%;
    margin-right: 0;
  }
  .form-builder-2-header .form-inline button {
    width: 100%;
    text-align: left;
  }
  .page-builder .btn-grid {
    margin-bottom: 7px;
    padding-right: 5px;
  }
  .page-builder .ge-canvas.ge-editing .row {
    padding: 15px;
  }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer {
    margin: 10px;
  }
  .page-builder .ge-canvas.ge-layout-desktop [class*=col-] {
    width: inherit !important;
  }
  .wizard-4 .step-container div.content {
    margin-top: 10px;
  }
  .nav-tabs .nav-item.show .nav-link {
    border-top: 0;
    border-bottom: 0;
  }
  .nav-tabs .nav-link {
    border-top: 0;
    border-bottom: 0;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus {
    border-top: 0;
    border-bottom: 0;
  }
  .border-tab.nav-tabs .nav-item {
    width: 100% !important;
  }
  .border-tab .ecommerce-widget .progress-showcase {
    margin-top: 15px;
  }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none;
  }
  .editor_container .CodeMirror {
    min-height: 190px;
    height: 190px;
  }
  .order-history table.dataTable.table thead th {
    padding-right: 60px;
  }
  .border-tab.nav-left .nav-link {
    text-align: center;
    border-left: none;
  }
  .border-tab.nav-right .nav-link {
    text-align: center;
    border-right: none;
  }
  .border-tab.nav-tabs {
    margin-bottom: 10px;
  }
  .tab-content ~ .nav-tabs.border-tab {
    margin-top: 10px;
  }
  .nav-pills {
    text-align: center;
  }
  .crm-overall {
    margin: 0 -15px -15px;
  }
  .product-color li {
    width: 15px;
    height: 15px;
  }
  .product-social li a {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
  .product-social li:nth-child(n+2) {
    margin-left: 5px;
  }
  [dir=rtl] .product-social li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 5px;
  }
  .product-page-main {
    padding: 15px;
  }
  .product-page-main .product-page-details h3 {
    font-size: 24px;
  }
  .todo .action-box.large {
    height: 25px;
    width: 25px;
  }
  .todo .action-box.large .icon {
    font-size: 14px;
    vertical-align: -3px;
  }
  .todo .todo-list-wrapper #todo-list li .task-container .task-label {
    font-size: 14px;
  }
  .todo .todo-list-wrapper .mark-all-tasks {
    right: 15px;
  }
  .datetime-picker label {
    text-align: left !important;
  }
  .redial-chart-block {
    text-align: center;
  }
  #nav-tabContent {
    margin-top: 15px;
  }
  .reset-password-box {
    width: 430px;
  }
  .auth-bg {
    padding: 25px 15px;
  }
  .auth-bg-effect {
    display: none;
  }
  .date-picker .text-right {
    text-align: left !important;
  }
  .dataTables_wrapper table.dataTable {
    margin-bottom: 15px !important;
  }
  ul.pagination li:before {
    line-height: 3;
  }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
    padding-left: 0 !important;
  }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
    padding-right: 0 !important;
  }
  div.dataTables_wrapper div.dataTables_length {
    margin-bottom: 15px;
  }
  div.dataTables_wrapper div.dataTables_length select {
    min-height: 30px;
  }
  div.dataTables_wrapper table.dataTable th,
  div.dataTables_wrapper table.dataTable td {
    padding: 0.5rem;
  }
  div.dataTables_wrapper div.dataTables_paginate {
    margin-top: 15px !important;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button {
    padding: 1px 7px;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous {
    font-size: 0;
    padding: 0;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous a {
    border: none !important;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous:before {
    content: "\e64a";
    font-family: themify;
    font-size: 11px;
    padding: 0 5px;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.next {
    font-size: 0;
    padding: 0;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.next a {
    border: none !important;
  }
  div.dataTables_wrapper div.dataTables_paginate .paginate_button.next:before {
    content: "\e649";
    font-family: themify;
    font-size: 11px;
    padding: 0 5px;
  }
  div.dataTables_wrapper .dataTables_filter {
    margin-bottom: 15px;
  }
  div.dataTables_wrapper .dataTables_filter input[type=search] {
    height: 30px;
  }
  .page-wrapper .page-body-wrapper .user-profile .profile-img-style {
    padding: 15px;
  }
  .page-wrapper .page-body-wrapper .user-profile .profile-img-style .img-container {
    margin-top: 15px;
  }
  .page-wrapper .page-body-wrapper .user-profile hr {
    margin: 15px 0;
  }
  .page-wrapper .page-body-wrapper .user-profile .like-comment {
    margin-top: 15px;
  }
  .jvector-map-height {
    height: 250px;
  }
  .user-profile .order-sm-0 {
    order: -1;
  }
  .user-profile .hovercard .info .ttl-info {
    text-align: center !important;
  }
  .user-profile .hovercard .info .ttl-xs-mt {
    margin-top: 20px;
  }
  .icon-hover-bottom .form-group {
    display: block !important;
    margin-bottom: 0;
  }
  .icon-hover-bottom .form-group input {
    width: 100% !important;
  }
  .icon-hover-bottom .form-group .btn {
    margin-top: 15px;
  }
  .icon-hover-bottom .icon-popup {
    padding: 15px;
  }
  .icon-hover-bottom svg.climacon {
    height: 50px;
    width: 50px;
  }
  .blog-box.blog-shadow:before {
    box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
  }
  .blog-box .blog-details p,
  .blog-box .blog-details .single-blog-content-top {
    margin-top: 15px;
  }
  .blog-box .blog-details h4 {
    margin-top: 15px;
  }
  .blog-box .blog-details .blog-social {
    margin-top: 15px;
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 20px;
  }
  .blog-box .blog-details .blog-social li + li > span {
    display: none;
  }
  .blog-box .blog-details .blog-social li:first-child {
    margin-right: 10px;
    padding-right: 20px;
  }
  .comment-box {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .comment-box ul ul {
    margin-left: 30px;
  }
  .comment-box li .d-flex {
    display: block;
  }
  .comment-box li .d-flex img {
    height: 50px;
    width: 50px;
    padding: 3px;
    margin-bottom: 10px;
  }
  .ace-editor {
    height: 320px;
  }
  .gallery > a {
    margin-bottom: 15px;
    text-align: center;
  }
  .card .card-header h5 {
    font-size: 17px;
  }
  .theme-form .form-group {
    margin-bottom: 15px;
  }
  .page-wrapper .page-body-wrapper .default-according .card .card-header,
  .page-wrapper .page-body-wrapper .default-according .card .card-body,
  .page-wrapper .page-body-wrapper .default-according .card .card-footer {
    padding: 0.75rem 1.25rem;
  }
  .page-wrapper .page-body-wrapper .card {
    margin-bottom: 20px;
  }
  .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-t-30,
  .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-t-30,
  .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-t-30 {
    margin-top: 20px !important;
  }
  .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-b-30,
  .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-b-30,
  .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-b-30 {
    margin-bottom: 20px !important;
  }
  .page-wrapper .page-body-wrapper .page-title {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .page-wrapper .page-body-wrapper .page-title .row h3 {
    font-size: 20px;
  }
  .alert-primary.inverse {
    background-color: transparent;
  }
  .alert-secondary.inverse {
    background-color: transparent;
  }
  .alert-success.inverse {
    background-color: transparent;
  }
  .alert-danger.inverse {
    background-color: transparent;
  }
  .alert-warning.inverse {
    background-color: transparent;
  }
  .alert-info.inverse {
    background-color: transparent;
  }
  .alert-light.inverse {
    background-color: transparent;
  }
  .alert-dark.inverse {
    background-color: transparent;
  }
  .alert .close {
    height: 100%;
  }
  .user-profile hr {
    margin: 15px 0;
  }
  .user-profile .hovercard .cardheader {
    height: 300px;
  }
  .user-profile .hovercard .info {
    padding: 20px;
  }
  .user-profile .hovercard .info .user-designation {
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    margin-bottom: 15px;
    padding: 15px 0px;
  }
  .user-profile .hovercard .social-media a {
    margin-right: 0px;
    font-size: 16px;
  }
  .user-profile .hovercard .follow .follow-num {
    font-size: 20px;
  }
  .user-profile .profile-img-style .pictures img {
    margin-bottom: 10px;
  }
  .custom-card .card-footer > div h6 {
    font-size: 14px;
    font-weight: 600;
  }
  .custom-card .card-footer > div h3 {
    font-size: 24px;
  }
  .calendar-wrap .fc-toolbar .fc-center {
    margin-top: 10px;
  }
  .calendar-wrap a.fc-more {
    font-size: 0.7em;
  }
  .mega-inline {
    display: block;
  }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important;
  }
  .blog-box.blog-shadow .blog-details {
    padding: 15px;
  }
  .blog-box.blog-shadow .blog-details p {
    margin-bottom: 0;
  }
  .blog-box.blog-shadow .blog-details h4 {
    margin-bottom: 10px;
  }
  .blog-box.blog-list .blog-details {
    padding-top: 0;
  }
  .blog-box .blog-details {
    padding: 20px;
  }
  .blog-box .blog-date span {
    font-size: 25px;
  }
  .blog-single .blog-box .blog-details {
    padding: 0;
  }
  .error-wrapper {
    padding: 20px 0;
  }
  .error-wrapper .btn {
    margin-top: 15px;
  }
  .error-wrapper .img-100 {
    width: 50px !important;
  }
  .error-wrapper .error-heading {
    margin-top: 20px;
  }
  .error-wrapper .error-heading .cloud-second {
    margin-top: -60px;
  }
  .error-wrapper .error-heading .headline {
    font-size: 150px;
  }
  .error-wrapper .maintenance-heading .cloud-second {
    display: none;
  }
  .error-wrapper .maintenance-heading .headline {
    font-size: 25px;
    margin-top: -10%;
    letter-spacing: 2px;
  }
  .comingsoon .comingsoon-inner .coming-soon-bottom-link {
    margin-top: 20px;
    line-height: 1;
  }
  .comingsoon .comingsoon-inner h5 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .comingsoon .comingsoon-inner .countdown {
    padding: 20px 0px;
  }
  .comingsoon .comingsoon-inner .countdown ul li {
    margin: 0 7px;
  }
  .comingsoon .comingsoon-inner .countdown .title {
    font-size: 12px;
  }
  .comingsoon .comingsoon-inner .countdown .time {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    margin: 0 auto;
  }
  .stepwizard {
    margin-bottom: 15px;
  }
  .f1 .f1-steps {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .wizard-4 ul.anchor {
    width: 100%;
    padding-bottom: 15px;
  }
  .wizard-4 .step-container {
    width: 100%;
  }
  .status-widget .text-sm-right {
    text-align: right;
  }
  .mega-menu {
    padding-left: 15px;
  }
  .mega-menu .onhover-show-div {
    height: 405px;
    left: 0;
    top: 61px;
    padding: 15px;
  }
  .mega-menu .lg-mt {
    margin-top: 10px;
  }
  .mega-menu .xs-mt {
    margin-top: 15px;
  }
  .nav-md-mt {
    margin-top: 15px;
  }
  .navs-icon {
    padding: 15px;
  }
  .navs-icon .main-section {
    padding-top: 15px;
  }
  .navs-icon .separator {
    margin: 15px 0;
  }
  .nav-list {
    padding: 15px;
  }
  .navs-dropdown button {
    margin-top: 15px;
  }
  .navs-dropdown .onhover-show-div {
    top: 64px;
  }
  .lg-mt {
    margin-top: 15px;
  }
  #aniimated-thumbnials a:last-child img {
    margin-bottom: 15px;
  }
  .tilt-showcase .mt-4 {
    margin-top: 15px !important;
  }
  .tilt-showcase ol .m-b-20 {
    margin-bottom: 15px !important;
  }
  .xs-mt {
    margin-top: 15px;
  }
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px;
  }
  .animated-modal .form-group .form-control {
    width: 50%;
    margin: 0 auto;
  }
  .animated-modal .animated-modal-md-mb {
    margin-bottom: 15px !important;
  }
  .steps-md-mt {
    margin-top: 15px;
  }
  .steps-sizing-sm-mb {
    margin-bottom: 15px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 96% !important;
  }
  #API-chield-row_wrapper #API-chield-row tbody tr td:first-child {
    min-width: 20px;
  }
  .chart-block .flot-chart-container p#choices {
    width: 100%;
  }
  .chart-block .flot-chart-container #toggling-series-flot {
    width: 100% !important;
  }
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 15px;
  }
  .ecommerce-widget .progress-showcase {
    margin-top: 15px;
  }
  .reset-password-box .theme-form .form-group .btn {
    margin-top: 13px !important;
  }
  .gallery-with-description a > div {
    margin-bottom: 15px;
  }
  .gallery-img-mb-sm {
    margin-bottom: 15px;
  }
  .xm-mb-peity {
    margin-bottom: 8px;
  }
  .authentication-main {
    padding: 30px 15px;
  }
  .translate_wrapper.active .more_lang:before, .translate_wrapper.active .more_lang:after {
    display: none;
  }
  .translate_wrapper.active .more_lang.active {
    position: fixed;
    width: calc(100vw - 30px);
    left: 15px;
    top: 60px;
  }
  .onhover-dropdown:hover .onhover-show-div {
    opacity: 0;
    transform: none;
    visibility: hidden;
  }
  .onhover-dropdown:hover .onhover-show-div.active {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
    border-radius: 5px;
    overflow: hidden;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    width: calc(100vw - 30px);
    position: fixed;
    top: 60px;
    right: 12px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
    width: calc(100vw - 30px);
    position: fixed;
    top: 60px;
    left: 15px !important;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown {
    left: 15px;
  }
  .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
    top: 43px;
  }
  .wizard-4 .action-bar .btn {
    margin: 0 2px;
  }
  .dropdown-basic .btn-group {
    margin-right: unset;
  }
}
@media only screen and (max-width: 480px) {
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    width: 280px;
  }
  .page-wrapper.only-body .page-header .header-wrapper .nav-right {
    display: none;
  }
  .chat-menu {
    top: 120px;
  }
  .product-wrapper-grid.list-view .product-box {
    display: block;
  }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 100%;
  }
  .pagination-lg .page-link {
    font-size: 1rem;
  }
  h6 {
    font-size: 14px;
  }
  .prooduct-details-box .flex-grow-1 {
    margin-left: 0rem !important;
  }
  .knob-block .chart-clock-main {
    margin: auto;
    width: auto;
  }
  .knob-block .chart-clock-main .clock-medium {
    position: absolute;
    left: 33px;
    top: 33px;
  }
  .knob-block .chart-clock-main .clock-medium canvas {
    width: 180px !important;
    height: 180px !important;
  }
  .knob-block .chart-clock-main .clock-small {
    left: 34px;
    top: 74px;
  }
  .knob-block .chart-clock-main .clock-large {
    left: 0;
    top: 0;
    margin: 0 auto;
  }
  .knob-block .chart-clock-main .clock-large canvas {
    width: 245px !important;
    height: 245px !important;
  }
  .knob-block > div:not(.chart-clock-main) canvas {
    width: 245px !important;
    height: 245px !important;
  }
  .wizard-4 .action-bar .btn {
    width: 100%;
  }
  .wizard-4 .action-bar .btn + .btn {
    margin-top: 10px;
  }
  ul.notification-dropdown.onhover-show-div {
    width: 260px;
    right: -80px;
  }
  ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
    right: 89px !important;
  }
  .inline-block-sm {
    display: inline-block;
  }
  .pagination-lg .page-link {
    padding: 0.3rem 0.65rem;
  }
  .nav-pills li {
    width: 100%;
  }
  .reset-password-box {
    width: 290px;
  }
  .icon-hover-bottom .icon-first {
    margin-right: 10px;
  }
  .icon-hover-bottom .icon-popup .icon-class {
    display: none;
  }
  .error-wrapper .maintenance-heading {
    margin-top: 0;
  }
  .custom-card {
    padding: 0;
  }
  .custom-card .card-footer > div h3 {
    font-size: 20px;
  }
  .custom-card .card-social li {
    padding: 5px 0;
  }
  .custom-card .profile-details h6 {
    margin-bottom: 15px;
  }
  .custom-card .profile-details h4 {
    font-size: 20px;
  }
  .custom-card .card-profile img {
    height: 93px;
  }
  .card .card-header .card-header-right i {
    margin: 0 3px;
    font-size: 14px;
  }
  .card .card-header > span + span {
    display: none;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -50px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    bottom: -40px;
  }
  .dropdown-basic {
    text-align: center;
  }
  .dropup-basic {
    text-align: center;
  }
  .blog-box.blog-shadow .blog-details {
    padding: 10px;
  }
  .blog-box.blog-shadow .blog-details h4 {
    font-size: 14px;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 83px;
    height: 83px;
  }
  .mega-menu {
    padding-left: 20px;
    padding-top: 3px;
  }
  .mega-menu .onhover-show-div {
    height: 405px;
    left: 0;
    top: 61px;
    padding: 15px;
  }
  .badge {
    padding: 0.42em 0.7em;
  }
  .alert-dismissible .close {
    top: -1px;
  }
  .alert-dismissible .close span {
    font-size: 19px;
  }
  .notify-alert {
    width: 90%;
  }
  .chat-box .chat-right-aside .chat .chat-header {
    display: block;
  }
  .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
    margin-top: 15px;
    padding-left: 10px;
  }
}
@media only screen and (max-width: 420px) {
  .product-sidebar.open {
    top: -75px;
  }
  .alert-theme {
    font-size: 12px;
    min-width: 300px;
  }
  .alert-theme button {
    right: 30px !important;
  }
  .alert-theme i {
    display: none;
  }
  .alert-theme span + span + span {
    padding: 15px;
  }
  .and-many-more {
    font-size: 37px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
    width: 112px;
  }
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 112px;
    width: 190px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper > div {
    width: 80px;
  }
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 80px;
    width: 210px;
  }
  .login-card .login-main .theme-form .link {
    position: unset;
  }
}
@media only screen and (max-width: 360px) {
  .product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group {
    width: 45%;
  }
  .prooduct-details-box .close {
    right: 0;
    top: 5px;
  }
  .prooduct-details-box .btn {
    padding: 4px 8px;
  }
  .prooduct-details-box .d-flex {
    padding: 15px;
    display: block;
  }
  .prooduct-details-box .d-flex .flex-grow-1 {
    margin-top: 15px;
  }
  .datepicker--nav-action {
    width: 20px;
    margin-left: -10px;
    background-color: transparent;
  }
  .datepicker--nav-action path {
    stroke: var(--theme-default);
  }
  .clockpicker-align-top {
    top: 426px !important;
  }
  .note {
    min-width: 250px;
  }
  .datepicker {
    width: 239px;
  }
  .add-post form .m-checkbox-inline label {
    margin-bottom: 8px;
  }
  .add-post form .m-checkbox-inline label:last-child {
    margin-bottom: 0;
  }
  .map-js-height {
    height: 250px;
  }
  .bar-chart-widget .earning-details i {
    right: -73px;
  }
  .status-widget svg {
    width: 18px;
    height: 18px;
  }
  .tabs-scoial.border-tab.nav-tabs .nav-item .nav-link {
    padding: 10px 5px;
    font-size: 14px;
  }
  .user-profile .hovercard .user-image .share-icons {
    right: 6px;
    top: -15px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon {
    width: 25px;
    height: 25px;
  }
  .user-profile .hovercard .user-image .share-icons li .social-icon i {
    font-size: 14px;
  }
  .social-chat .other-msg {
    margin-left: 15px;
  }
  .social-header h5 span.pull-right {
    display: none;
  }
  .timeline-small .d-flex .timeline-round.timeline-line-1:after {
    bottom: -74px;
    height: 56px;
  }
  .timeline-small .d-flex .timeline-round.small-line:after {
    bottom: -32px;
    height: 14px;
  }
  .timeline-small .d-flex .timeline-round.medium-line:after {
    bottom: -59px;
    height: 40px;
  }
  .timeline-small .d-flex .flex-grow-1 p {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }
  .custom-card .dashboard-card {
    padding-top: 0;
  }
  .custom-card .card-profile img {
    bottom: 25px;
  }
  .custom-card .card-footer > div h6 {
    font-size: 12px;
  }
  .flot-chart-container {
    height: 230px;
  }
  .map-block {
    height: 250px;
  }
  .img-cropper .docs-tooltip {
    font-size: 11px;
  }
  .img-cropper .docs-toggles .btn-group .btn {
    padding: 0.375rem 0.2rem;
  }
  .blog-box .blog-details p {
    line-height: 1.5;
  }
  .blog-box .blog-details .blog-social li:last-child {
    padding-left: 0;
  }
  .blog-box .blog-details-main .blog-social li {
    padding: 0 10px;
  }
  .button-builder-wrap .btn-lg {
    font-size: 18px;
  }
  .tabs-responsive-side {
    max-width: 100%;
  }
  .browser-widget .d-flex i {
    font-size: 52px;
  }
  .browser-widget .d-flex .flex-grow-1 h4 {
    font-size: 20px;
  }
  .bar-chart-widget .bottom-content .num {
    font-size: 17px;
  }
  .search-page .theme-form .input-group .form-control-plaintext {
    padding-left: 10px;
  }
  .search-page .theme-form .input-group .btn {
    padding: 6px 4px;
  }
  .search-page .info-block {
    padding: 20px;
  }
  .wizard-4 ul.anchor {
    padding-top: 15px;
  }
  .wizard-4 .msg-box {
    top: 40px !important;
  }
  .xs-width-100 {
    min-width: 100%;
  }
  .email-wrap .action-wrapper .actions li {
    margin-right: 5px;
  }
  .email-wrap .email-body .attachment .list-inline .list-inline-item {
    padding-right: 5px;
  }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 65px;
    height: 65px;
  }
  .email-wrap .email-right-aside .email-top .dropdown-menu {
    left: -58px;
  }
  .form-builder #components .component .form-group #button1id {
    margin-bottom: 15px;
  }
  .default-according .card .card-header i {
    font-size: 14px;
    top: 24px;
  }
  .default-according .card .btn-link {
    display: block;
    width: 100%;
    padding-left: 17px;
  }
  .star-ratings .stars .title {
    line-height: 1;
  }
  .user-profile .hovercard .social-media a {
    padding: 0 6px;
  }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 93% !important;
  }
  .step-container {
    height: 280px !important;
  }
  .sort-panel .d-inline .btn {
    margin-left: 87px;
  }
  .jsgrid .jsgrid-pager-container .jsgrid-pager .jsgrid-pager-page {
    padding: 0.5em 0.62em;
  }
  .chat-box .chat-right-aside {
    flex: 0 0 96%;
    max-width: 96%;
  }
  .chat-box .chat-right-aside .chat .chat-message {
    width: calc(100% - 20px);
  }
  .error-wrapper .error-heading .headline {
    font-size: 100px;
  }
  .lg-toolbar .lg-icon {
    width: 40px;
  }
  .calendar-wrap a.fc-more {
    font-size: 0.59em;
  }
  .calendar-wrap .fc th {
    font-size: 10px;
  }
  .modal-footer {
    display: block;
    text-align: center;
  }
  .order-box .qty li {
    font-size: 14px;
  }
  .order-box .qty li span {
    font-size: 14px;
  }
  .order-box ul li span {
    text-align: right;
  }
  .order-box ul li label {
    text-align: right;
    font-size: 14px;
  }
  .order-box .sub-total li .count {
    font-size: 14px;
  }
  .checkout .text-end {
    text-align: left !important;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 576px) {
  .product-wrapper-grid.list-view .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sidebar-wrapper .back-btn {
    display: none;
  }
}
@media (width: 768px) and (height: 1024px) {
  .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 210px);
  }
}
/**=====================
     62. Responsive CSS Ends
==========================**/